/*
 * @Author: zy 848175192@qqcom
 * @Date: 2022-12-02 17:01:10
 * @LastEditors: zy 848175192@qqcom
 * @LastEditTime: 2022-12-05 14:26:50
 * @FilePath: \rc-pc-wbe:\shenYuan\cn-medical-uccn\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'windi.css'
import VueLazyload from "vue-lazyload";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
require('@/utils/rem')
Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.use(VueLazyload,{

})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
